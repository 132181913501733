import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoadScript } from '@react-google-maps/api';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { CustomTextField } from '../../components/CustomTextField';
import { CustomSelectField } from '../../components/CustomSelectField';
import { CustomDateTimePicker } from '../../components/CustomDateTimePicker';
import { TripPlanner } from '../../components/TripPlanner';
import { RectButton } from '../../components/Button';
import { apiFetch } from '../../utilities/ApiFetch';
import { validateEmail } from '../../utilities/Functions';
import { CircularLoading } from '../../components/CircularLoading';
import { useTranslation } from 'react-i18next';
import { SNACKVARIANT, initialFieldState } from '../../utilities/Constants';
import { CustomModal } from '../../components/CustomModal';
import { BoxSelect } from '../../components/BoxSelect';
import { WelcomeBanner } from '../../components/WelcomeBanner';
import dayjs from 'dayjs';
import './Reservation.css';

import suv from '../../assets/Vehicle/Cadillac-Escalade-1.png';
import sedan from '../../assets/Vehicle/Cadillac-Lyric.png';
import ReserveFrontImg from '../../assets/Image/Reserve-Page-Welcome.jpg';
import luxvan from '../../assets/Vehicle/Luxvan-1.png';

const libraries = ['places'];

export const Reservation = () => {
  const { t } = useTranslation('translation');
  const { enqueueSnackbar } = useSnackbar();

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const handleOpenViewModal = () => {
    if (validateForm()) {
      setIsViewModalOpen(true);
    }
  };

  const handleCloseViewModal = () => {
    setAgreePrice(false);
    setAgreeTerm(false);
    setCarSelected(null);
    setIsViewModalOpen(false);
  };

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(initialFieldState);
  const [lastName, setLastName] = useState(initialFieldState);
  const [phone, setPhone] = useState(initialFieldState);
  const [email, setEmail] = useState(initialFieldState);
  const [confirmEmail, setConfirmEmail] = useState(initialFieldState);
  const [passengerCount, setPassengerCount] = useState(1);
  const [luggageCount, setLugguageCount] = useState(0);
  const [isRoundTrip, setIsRoundTrip] = useState('No');
  const [isSameDay, setIsSameDay] = useState(true);
  const [tripType, setTripType] = useState('Transfer');
  const [pickupDateTime, setPickupDateTime] = useState(initialFieldState);
  const [hourCount, setHourCount] = useState(3);
  const [hourCountHelper, setHourCountHelper] = useState(t('reserve.hourKm3'));
  const [stops, setStops] = useState([]);
  const [returnStops, setReturnStops] = useState([]);
  const [specialRequest, setSpecialRequest] = useState(initialFieldState);
  const [agreeTerm, setAgreeTerm] = useState(false);
  const [addAPet, setAddAPet] = useState(false);
  const [meetGreet, setMeetGreet] = useState(false);
  const [toddlerSeat, setToddlerSeat] = useState(false);
  const [carSeat, setCarSeat] = useState(false);
  const [boosterSeat, setBoosterSeat] = useState(false);
  const [babySeat, setBabySeat] = useState(false);
  const [agreePrice, setAgreePrice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [returnDateTime, setReturnDateTime] = useState(initialFieldState);

  const [distance, setDistance] = useState([]);
  const [returnDistance, setReturnDistance] = useState([]);
  const [yulToOrigin, setYulToOrigin] = useState(0);

  const [carSelected, setCarSelected] = useState(null);
  const [price, setPrice] = useState({});
  const [isPickUpdating, setIsPickUpdating] = useState(false);
  const [isReturnUpdating, setIsReturnUpdating] = useState(false);
  const [arrivalFlight, setArrivalFlight] = useState(initialFieldState);
  const [departureFlight, setDepartureFlight] = useState(initialFieldState);

  const checkTime = (dateTime) => {
    if (dateTime) {
      const midnight = dateTime.startOf('day');
      const fourThirtyAM = midnight.clone().add(4, 'hours').add(30, 'minutes');
      return dateTime.isAfter(midnight) && dateTime.isBefore(fourThirtyAM);
    }

    return false;
  };

  const getRate = useCallback(
    (dist) => {
      let rate = 0;
      if (dist === 0) {
        rate = 0;
      } else if (dist > 0 && dist <= 150) {
        rate = -0.00244 * dist + 3.61571;
      } else if (dist > 150 && dist <= 200) {
        rate = -0.00244 * dist + 3.2281305;
      } else if (dist > 200 && dist <= 300) {
        rate = -0.00244 * dist + 3.049723;
      } else if (dist > 300 && dist <= 600) {
        rate = -0.00244 * dist + 3.854127;
      } else if (dist > 600 && dist <= 700) {
        rate = -0.00244 * dist + 4.049977;
      } else {
        rate = 2.672212;
      }

      if (carSelected === 'Suv') {
        return rate;
      } else if (carSelected === 'Sedan') {
        return rate - rate * 0.16;
      } else {
        if (dist > 150) {
          return rate + 1.55;
        } else {
          return rate * 1.6;
        }
      }
    },
    [carSelected],
  );

  // UseEffect to calculate the price
  useEffect(() => {
    let temp = {};

    if (tripType === 'Transfer' || tripType === 'Airport Transfer') {
      let initRate = 0;
      let initFee = 0;

      if (carSelected === 'Suv') {
        initRate = 4.5;
        initFee = 50;
      } else if (carSelected === 'Sedan') {
        initRate = 3.5;
        initFee = 50;
      } else {
        initRate = 5.66667;
        initFee = 130;
      }

      if (stops.length > 1) {
        temp.basePrice = 0;
        const stopDistRate = getRate(distance);
        temp.basePrice += 30 * initRate;
        temp.basePrice += initFee;

        if (distance > 30) {
          temp.distanceFee = (distance - 30) * stopDistRate;
        } else {
          temp.stopFee = (stops.length - 2) * 50;
        }
      }

      if (returnStops.length > 1) {
        temp.returnBasePrice = 0;
        const returnStopDistRate = getRate(returnDistance);
        temp.returnBasePrice += 30 * initRate;
        temp.returnBasePrice += initFee;

        if (returnDistance > 30) {
          temp.returnDistanceFee = (returnDistance - 30) * returnStopDistRate;
        } else {
          temp.returnStopFee = (returnStops.length - 2) * 50;
        }
      }
    } else {
      const hourlyDistRate = getRate(yulToOrigin);
      let hourlyPrice = 0;

      if (hourCount >= 3 && hourCount < 6 && yulToOrigin > 35) {
        temp.tripToOrigin = (yulToOrigin - 35) * hourlyDistRate;
      } else if (hourCount >= 6 && hourCount < 10 && yulToOrigin > 100) {
        temp.tripToOrigin = (yulToOrigin - 100) * hourlyDistRate;
      } else if (hourCount >= 10 && yulToOrigin > 200) {
        temp.tripToOrigin = (yulToOrigin - 200) * hourlyDistRate;
      }

      if (carSelected === 'Suv') {
        if (hourCount > 3 && hourCount <= 6) {
          hourlyPrice = 140;
        } else if (hourCount > 6) {
          hourlyPrice = 130;
        } else {
          hourlyPrice = 150;
        }
      } else if (carSelected === 'Sedan') {
        hourCount < 8 ? (hourlyPrice = 120) : (hourlyPrice = 100);
      } else {
        if (hourCount > 3 && hourCount <= 6) {
          hourlyPrice = 195;
        } else if (hourCount > 6) {
          hourlyPrice = 175;
        } else {
          hourlyPrice = 225;
        }
      }

      temp.hourlyPrice = hourCount * hourlyPrice;
    }

    let preTaxTotal = 0;
    for (let key in temp) {
      preTaxTotal += temp[key];
    }

    temp.gst = preTaxTotal * 0.05;
    temp.qst = preTaxTotal * 0.09975;

    const pickUpOvernight = pickupDateTime.value
      ? checkTime(pickupDateTime.value)
      : false;
    const returnOvernight = returnDateTime.value
      ? checkTime(returnDateTime.value)
      : false;

    if (pickUpOvernight || returnOvernight) {
      temp.overnight = 0;

      if (pickUpOvernight) {
        temp.overnight += 50;
      }

      if (returnOvernight) {
        temp.overnight += 50;
      }
    }

    if (
      stops[0]?.value?.toLowerCase().includes('yul') ||
      stops[0]?.value?.includes('Bd Roméo Vachon Nord (Arrivées)')
    ) {
      temp.airportFee = 15;
    }

    if (
      returnStops[0]?.value?.toLowerCase().includes('yul') ||
      returnStops[0]?.value?.includes('Bd Roméo Vachon Nord (Arrivées)')
    ) {
      temp.returnAirportFee = 15;
    }

    if (addAPet) {
      temp.petFee = 50;
    }

    if (meetGreet) {
      temp.meetGreetFee = 25;
    }

    let total = 0;
    for (let key in temp) {
      total += temp[key];
    }
    temp.total = total;

    setPrice(temp);
  }, [
    carSelected,
    distance,
    returnDistance,
    stops,
    returnStops,
    hourCount,
    tripType,
    pickupDateTime.value,
    returnDateTime.value,
    yulToOrigin,
    getRate,
    addAPet,
    meetGreet,
  ]);

  const handleChange = (setter, value) => {
    setter({ ...initialFieldState, value });
  };

  const formatPhoneNumber = (input) => {
    const match = input.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return input;
  };

  const handlePhoneChange = (value) => {
    setPhone({ ...initialFieldState, value });
  };

  const validateForm = () => {
    let isValid = true;

    if (firstName.value === '') {
      setFirstName({
        value: '',
        error: true,
        helperText: t('reserve.nameRequired'),
      });
      isValid = false;
    }

    if (lastName.value === '') {
      setLastName({
        value: '',
        error: true,
        helperText: t('reserve.nameRequired'),
      });
      isValid = false;
    }

    if (phone.value === '') {
      setPhone({
        value: '',
        error: true,
        helperText: t('reserve.phoneRequired'),
      });
      isValid = false;
    } else if (!/^[0-9+()\- ]+$/.test(phone.value)) {
      setPhone({
        value: '',
        error: true,
        helperText: t('reserve.phoneInvalid'),
      });
      isValid = false;
    }

    if (email.value === '') {
      setEmail({
        value: '',
        error: true,
        helperText: t('reserve.emailRequired'),
      });
      isValid = false;
    } else if (!validateEmail(email.value)) {
      setEmail({
        value: '',
        error: true,
        helperText: t('reserve.emailInvalid'),
      });
      isValid = false;
    }

    if (confirmEmail.value === '') {
      setConfirmEmail({
        value: '',
        error: true,
        helperText: t('reserve.emailRequired'),
      });
      isValid = false;
    } else if (email.value !== confirmEmail.value) {
      setConfirmEmail({
        value: '',
        error: true,
        helperText: t('reserve.emailNotMatch'),
      });
      isValid = false;
    }

    if (pickupDateTime.value === '') {
      setPickupDateTime({
        value: '',
        error: true,
        helperText: t('reserve.dateRequired'),
      });
      isValid = false;
    }

    let stopValid = true;

    const updatedStops = stops.map((stop) => {
      if (stop.value.trim() === '') {
        isValid = false;
        stopValid = false;
        return {
          ...stop,
          error: true,
          helperText: t('reserve.locationRequired'),
        };
      } else {
        return { ...stop, error: false, helperText: ' ' };
      }
    });
    setStops(updatedStops);

    if (stopValid) {
      if (
        stops.length === 2 &&
        stops[0].value === stops[1].value &&
        (tripType === 'Transfer' || tripType === 'Airport Transfer')
      ) {
        isValid = false;
        setStops([
          {
            ...stops[0],
          },
          {
            ...stops[1],
            error: true,
            helperText: t('reserve.locationNotMatch'),
          },
        ]);
      } else if (
        stops.length > 2 &&
        stops[0].value === stops[stops.length - 1].value
      ) {
        let cond = true;

        for (let i = 1; i < stops.length - 1; i++) {
          if (stops[i].value !== stops[i + 1].value) {
            cond = false;
          }
        }

        if (cond) {
          isValid = false;
          const temp = stops;
          temp[temp.length - 2].error = true;
          temp[temp.length - 2].helperText = t('reserve.locationNotMatch');
          setStops(temp);
        }
      }
    }

    if (isRoundTrip === 'Yes') {
      if (returnDateTime.value === '') {
        setReturnDateTime({
          value: '',
          error: true,
          helperText: t('reserve.dateRequired'),
        });
        isValid = false;
      }

      let returnStopValid = true;

      const updatedStops = returnStops.map((stop) => {
        if (stop.value.trim() === '') {
          isValid = false;
          returnStopValid = false;
          return {
            ...stop,
            error: true,
            helperText: t('reserve.locationRequired'),
          };
        } else {
          return { ...stop, error: false, helperText: ' ' };
        }
      });
      setReturnStops(updatedStops);

      if (returnStopValid) {
        if (
          returnStops.length === 2 &&
          returnStops[0].value === returnStops[1].value &&
          (tripType === 'Transfer' || tripType === 'Airport Transfer')
        ) {
          isValid = false;
          setReturnStops([
            {
              ...returnStops[0],
            },
            {
              ...returnStops[1],
              error: true,
              helperText: t('reserve.locationNotMatch'),
            },
          ]);
        } else if (
          returnStops.length > 2 &&
          returnStops[0].value === returnStops[returnStops.length - 1].value
        ) {
          let cond = true;

          for (let i = 1; i < returnStops.length - 1; i++) {
            if (returnStops[i].value !== returnStops[i + 1].value) {
              cond = false;
            }
          }

          if (cond) {
            isValid = false;
            const temp = returnStops;
            temp[temp.length - 2].error = true;
            temp[temp.length - 2].helperText = t('reserve.locationNotMatch');
            setReturnStops(temp);
          }
        }
      }
    }

    if (!isValid) {
      handleAlert(t('reserve.fillFields'), SNACKVARIANT.WARNING);
    }

    return isValid;
  };

  const validateModal = () => {
    let isValid = true;

    if (carSelected === null) {
      handleAlert(t('reserve.selectCarRequired'), SNACKVARIANT.WARNING);
      isValid = false;
    }

    if (agreeTerm === false || agreePrice === false) {
      handleAlert(t('reserve.agreeRequired'), SNACKVARIANT.WARNING);
      isValid = false;
    }

    return isValid;
  };

  const formatToEST = (datetime) => {
    return dayjs(datetime).format('YYYY-MM-DDTHH:mm:ss.SSS');
  };

  const handleSubmit = async () => {
    try {
      if (validateModal()) {
        handleCloseViewModal();
        setIsLoading(true);
        const data = {
          firstName: firstName.value,
          lastName: lastName.value,
          phone: phone.value,
          email: email.value,
          passengerCount,
          luggageCount,
          isRoundTrip,
          tripType,
          departureFlight: departureFlight.value,
          arrivalFlight: arrivalFlight.value,
          pickupDateTime: formatToEST(pickupDateTime.value),
          hourCount,
          stops,
          returnStops,
          specialRequest: specialRequest.value,
          returnDateTime: formatToEST(returnDateTime.value),
          distance,
          yulToOrigin,
          returnDistance,
          carSelected,
          price,
          addAPet,
          meetGreet,
          toddlerSeat,
          carSeat,
          boosterSeat,
          babySeat,
        };

        const response = await apiFetch('post', '/reserve', data);

        setIsLoading(false);

        if (response.status === 'SUCCESS') {
          handleAlert(t('reserve.reserveSuccess'), SNACKVARIANT.SUCCESS);
          navigate('/');
        } else if (response.reason === 'EMPTY_INPUTS') {
          handleAlert(t('reserve.fillFields'), SNACKVARIANT.WARNING);
        } else {
          handleAlert(t('reserve.reserveFail'), SNACKVARIANT.ERROR);
        }
      }
    } catch (error) {
      handleAlert(t('reserve.reserveFail'), SNACKVARIANT.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isRoundTrip === 'Yes') {
      if (pickupDateTime.value && returnDateTime.value) {
        setIsSameDay(
          dayjs(pickupDateTime.value).isSame(
            dayjs(returnDateTime.value),
            'day',
          ),
        );
      } else {
        setIsSameDay(true);
      }
    } else {
      setIsSameDay(true);
    }
  }, [isRoundTrip, pickupDateTime.value, returnDateTime.value]);

  const modalVehicle = useMemo(() => {
    if (passengerCount > 6 || luggageCount > 6) {
      return [
        {
          image: luxvan,
          title: t('reserve.luxuryVan'),
          passengers: '11',
          bags: '11',
        },
      ];
    } else if (passengerCount <= 3 && luggageCount <= 3) {
      return [
        { image: sedan, title: 'Sedan', passengers: '3', bags: '3' },
        { image: suv, title: 'Suv', passengers: '6', bags: '6' },
        {
          image: luxvan,
          title: t('reserve.luxuryVan'),
          passengers: '11',
          bags: '11',
        },
      ];
    } else {
      return [
        { image: suv, title: 'Suv', passengers: '6', bags: '6' },
        {
          image: luxvan,
          title: t('reserve.luxuryVan'),
          passengers: '11',
          bags: '11',
        },
      ];
    }
  }, [passengerCount, luggageCount, t]);

  return (
    <>
      <WelcomeBanner
        imgSrc={ReserveFrontImg}
        mainMsg={
          <>
            {t('reserve.reserveLow')}{' '}
            <span style={{ color: '#DDB989' }}> {t('reserve.online')}</span>
          </>
        }
      />
      <div className="container" style={{ padding: '50px 0px 30px 0px' }}>
        <div className="row">
          <div className="col-lg-8" autoComplete="off">
            <div>
              <div className="Reserve-gray-box">
                {/* NAME */}
                <div className="Reserve-padder">
                  <div>
                    <CustomTextField
                      label={t('reserve.firstName')}
                      variant="outlined"
                      value={firstName.value}
                      error={firstName.error}
                      helperText={firstName.helperText}
                      onValueChange={(e) =>
                        handleChange(setFirstName, e.target.value)
                      }
                      required={true}
                    />
                  </div>
                  <div>
                    <CustomTextField
                      label={t('reserve.lastName')}
                      variant="outlined"
                      value={lastName.value}
                      error={lastName.error}
                      helperText={lastName.helperText}
                      onValueChange={(e) =>
                        handleChange(setLastName, e.target.value)
                      }
                      required={true}
                    />
                  </div>
                </div>
                {/* NAME */}

                {/* EMAIL */}
                <div className="Reserve-padder">
                  <div>
                    <CustomTextField
                      label={t('reserve.email')}
                      variant="outlined"
                      value={email.value}
                      error={email.error}
                      helperText={email.helperText}
                      onValueChange={(e) =>
                        handleChange(setEmail, e.target.value)
                      }
                      required={true}
                    />
                  </div>
                  <div>
                    <CustomTextField
                      label={t('reserve.confirmEmail')}
                      variant="outlined"
                      value={confirmEmail.value}
                      error={confirmEmail.error}
                      helperText={confirmEmail.helperText}
                      onValueChange={(e) =>
                        handleChange(setConfirmEmail, e.target.value)
                      }
                      required={true}
                    />
                  </div>
                </div>
                {/* EMAIL */}

                {/* PHONE */}
                <div className="Reserve-padder">
                  <div>
                    <CustomTextField
                      label={t('reserve.phone')}
                      variant="outlined"
                      value={formatPhoneNumber(phone.value)}
                      error={phone.error}
                      helperText={phone.helperText}
                      onValueChange={(e) => handlePhoneChange(e.target.value)}
                      required={true}
                    />
                  </div>
                </div>
                {/* PHONE */}

                <hr className="Res-divider" />

                {/* PASSENGER AND LUGGAGE */}
                <div className="Reserve-padder">
                  <div>
                    <CustomTextField
                      type="number"
                      label={t('reserve.passengers')}
                      inputProps={{ min: 1, max: 11 }}
                      value={passengerCount}
                      onValueChange={(e) => setPassengerCount(e.target.value)}
                      required={true}
                    />
                  </div>
                  <div>
                    <CustomTextField
                      type="number"
                      label={t('reserve.luggage')}
                      inputProps={{ min: 0, max: 11 }}
                      value={luggageCount}
                      onValueChange={(e) => setLugguageCount(e.target.value)}
                      required={true}
                    />
                  </div>
                </div>
                {/* PASSENGER AND LUGGAGE */}

                {/* TRIP TYPE AND ROUND TRIP*/}
                <div className="Reserve-padder">
                  <div>
                    <CustomSelectField
                      label={t('reserve.roundTrip')}
                      options={
                        tripType !== 'Hourly'
                          ? [
                              {
                                key: t('reserve.yes'),
                                value: 'Yes',
                              },
                              { key: t('reserve.no'), value: 'No' },
                            ]
                          : [{ key: t('reserve.no'), value: 'No' }]
                      }
                      value={isRoundTrip}
                      onValueChange={(e) => {
                        if (e === 'No') {
                          setReturnStops([]);
                          setReturnDateTime(initialFieldState);
                          setReturnDistance(0);
                        }
                        setIsRoundTrip(e);
                      }}
                    />
                  </div>
                  <div>
                    <CustomSelectField
                      label={t('reserve.tripType')}
                      defaultValue="transfer"
                      options={[
                        { key: t('reserve.transfer'), value: 'Transfer' },
                        {
                          key: t('reserve.airportTransfer'),
                          value: 'Airport Transfer',
                        },
                        { key: t('reserve.hourly'), value: 'Hourly' },
                      ]}
                      value={tripType}
                      onValueChange={(e) => {
                        if (e === 'Hourly') {
                          setIsRoundTrip('No');
                          setReturnStops([]);
                          setReturnDateTime(initialFieldState);
                          setReturnDistance(0);
                        }
                        setTripType(e);
                      }}
                    />
                  </div>
                </div>
                {/* TRIP TYPE AND ROUND TRIP*/}

                {/* FLIGHT NUMBER */}
                {tripType === 'Airport Transfer' && (
                  <div className="Reserve-padder">
                    <div>
                      <CustomTextField
                        label={t('reserve.departureFlightNumber')}
                        variant="outlined"
                        value={departureFlight.value}
                        error={departureFlight.error}
                        helperText={departureFlight.helperText}
                        onValueChange={(e) =>
                          handleChange(setDepartureFlight, e.target.value)
                        }
                        required={false}
                      />
                    </div>
                    <div>
                      <CustomTextField
                        label={t('reserve.arrivalFlightNumber')}
                        variant="outlined"
                        value={arrivalFlight.value}
                        error={arrivalFlight.error}
                        helperText={arrivalFlight.helperText}
                        onValueChange={(e) =>
                          handleChange(setArrivalFlight, e.target.value)
                        }
                        required={false}
                      />
                    </div>
                  </div>
                )}
                {/* FLIGHT NUMBER */}

                {/* PICK UP DATE TIME AND HOUR COUNT */}
                <div className="Reserve-padder">
                  <div>
                    <CustomDateTimePicker
                      label={t('reserve.date')}
                      value={pickupDateTime.value}
                      error={pickupDateTime.error}
                      helperText={pickupDateTime.helperText}
                      onValueChange={(e) => handleChange(setPickupDateTime, e)}
                    />
                  </div>
                  {tripType === 'Hourly' ? (
                    <div>
                      <CustomTextField
                        type="number"
                        label={t('reserve.hours')}
                        inputProps={{
                          min: 3,
                          max: 24,
                        }}
                        value={hourCount}
                        onValueChange={(e) => {
                          if (e.target.value < 3) {
                            setHourCount(3);
                            handleAlert(
                              t('reserve.minHour', { min: 3 }),
                              SNACKVARIANT.WARNING,
                            );
                          } else if (e.target.value > 24) {
                            handleAlert(
                              t('reserve.maxHour'),
                              SNACKVARIANT.WARNING,
                            );
                            setHourCount(24);
                          } else {
                            if (e.target.value < 6) {
                              setHourCountHelper(t('reserve.hourKm3'));
                            } else if (
                              e.target.value >= 6 &&
                              e.target.value < 10
                            ) {
                              setHourCountHelper(t('reserve.hourKm6'));
                            } else {
                              setHourCountHelper(t('reserve.hourKm10'));
                            }
                            setHourCount(e.target.value);
                          }
                        }}
                        helperText={hourCountHelper}
                        required={true}
                      />
                    </div>
                  ) : isRoundTrip === 'Yes' ? (
                    <div>
                      <CustomDateTimePicker
                        label={t('reserve.returnDateTime')}
                        value={returnDateTime.value}
                        error={returnDateTime.error}
                        helperText={returnDateTime.helperText}
                        onValueChange={(e) =>
                          handleChange(setReturnDateTime, e)
                        }
                      />
                    </div>
                  ) : null}
                </div>
                {/* PICK UP DATE TIME AND HOUR COUNT */}

                <hr className="Res-divider" />

                {/* STOPS */}
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
                  libraries={libraries}
                >
                  <TripPlanner
                    tripType={tripType}
                    roundTrip={isSameDay && isRoundTrip === 'Yes'}
                    stops={stops}
                    setStops={setStops}
                    setDistance={setDistance}
                    setYulToOrigin={setYulToOrigin}
                    title={
                      isRoundTrip === 'Yes' && !isSameDay
                        ? t('reserve.initialTripPlanner')
                        : t('reserve.tripPlanner')
                    }
                    setUpdating={setIsPickUpdating}
                  />

                  {!isSameDay && isRoundTrip === 'Yes' && (
                    <>
                      <hr className="Res-divider" />
                      <TripPlanner
                        tripType={'Transfer'}
                        roundTrip={false}
                        stops={returnStops}
                        setStops={setReturnStops}
                        setDistance={setReturnDistance}
                        setYulToOrigin={setYulToOrigin}
                        title={t('reserve.returnTripPlanner')}
                        setUpdating={setIsReturnUpdating}
                      />
                    </>
                  )}
                </LoadScript>
                {/* STOPS */}

                <hr />

                {/* SPECIAL REQUESTS */}
                <div className="Res-special-checks">
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox onClick={() => setAddAPet(!addAPet)} />
                      }
                      label={t('reserve.addAPet')}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox onClick={() => setMeetGreet(!meetGreet)} />
                      }
                      label={t('reserve.meetGreet')}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => setToddlerSeat(!toddlerSeat)}
                        />
                      }
                      label={t('reserve.toddlerSeat')}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox onClick={() => setCarSeat(!carSeat)} />
                      }
                      label={t('reserve.carSeat')}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => setBoosterSeat(!boosterSeat)}
                        />
                      }
                      label={t('reserve.boosterSeat')}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox onClick={() => setBabySeat(!babySeat)} />
                      }
                      label={t('reserve.babySeat')}
                    />
                  </div>
                </div>
                {meetGreet && (
                  <p style={{ fontSize: '12px', color: 'red' }}>
                    *{t('reserve.meetGreetDesc')}
                  </p>
                )}
                <div>
                  <CustomTextField
                    label={t('reserve.specialRequests')}
                    variant="outlined"
                    multiline={true}
                    rows={5}
                    value={specialRequest.value}
                    error={specialRequest.error}
                    helperText={specialRequest.helperText}
                    onValueChange={(e) =>
                      handleChange(setSpecialRequest, e.target.value)
                    }
                  />
                </div>
                {/* SPECIAL REQUESTS */}

                <div className="Res-reserve-btn">
                  <RectButton
                    onClick={handleOpenViewModal}
                    loading={isPickUpdating || isReturnUpdating}
                  >
                    {t('reserve.continue')}
                  </RectButton>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 Reserve-info-box">
            <div className="Reserve-gray-box">
              <span className="Playball Reserve-span">{t('reserve.info')}</span>
              <ul className="Reserve-info-ul">
                <li>{t('reserve.infoDesc1')}</li>
                <li>{t('reserve.infoDesc2')}</li>
                <li>{t('reserve.infoDesc3')}</li>
                <li>{t('reserve.infoDesc4')}</li>
                <li>{t('reserve.infoDesc5')}</li>
              </ul>
              <span className="Playball Reserve-span">
                {t('reserve.cancel')}
              </span>
              <p>
                {t('reserve.cancelDesc')}{' '}
                <Link to="/About/Terms">Terms and Conditions</Link>
              </p>
              <span className="Playball Reserve-span">
                {t('reserve.contactUs')}
              </span>
              <p style={{ margin: '0px' }}>
                {t('reserve.phone')}: 514-975-3134
              </p>
              <p style={{ margin: '0px' }}>
                {t('reserve.email')}: info@platinumchauffeur.ca
              </p>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <CircularLoading />}
      <CustomModal
        isOpen={isViewModalOpen}
        onClose={handleCloseViewModal}
        title={t('reserve.confirmRes')}
        shouldShowSecondBtn={true}
        secondBtnLabel={t('reserve.reserveUp')}
        onClickSecond={handleSubmit}
      >
        <BoxSelect
          options={modalVehicle}
          onSelect={(e) => {
            setCarSelected(e);
          }}
        />

        {carSelected && (
          <div className="Res-modal-item">
            <h5>{t('reserve.priceEstimate')}</h5>
            {price.basePrice > 0 && (
              <p>
                <span>{t('reserve.basePrice') + ': '}</span>
                <span>{'$' + price.basePrice.toFixed(2)}</span>
              </p>
            )}
            {price.distanceFee > 0 && (
              <p>
                <span>{t('reserve.distanceFee') + ': '}</span>
                <span>{'$' + price.distanceFee.toFixed(2)}</span>
              </p>
            )}
            {price.stopFee > 0 && (
              <p>
                <span>{t('reserve.stopFee') + ': '}</span>
                <span>{'$' + price.stopFee.toFixed(2)}</span>
              </p>
            )}
            {price.returnBasePrice > 0 && (
              <p>
                <span>{t('reserve.returnBasePrice') + ': '}</span>
                <span>{'$' + price.returnBasePrice.toFixed(2)}</span>
              </p>
            )}
            {price.returnStopFee > 0 && (
              <p>
                <span>{t('reserve.stopFee') + ': '}</span>
                <span>{'$' + price.returnStopFee.toFixed(2)}</span>
              </p>
            )}
            {price.returnDistanceFee > 0 && (
              <p>
                <span>{t('reserve.returnDistanceFee') + ': '}</span>
                <span>{'$' + price.returnDistanceFee.toFixed(2)}</span>
              </p>
            )}
            {price.tripToOrigin > 0 && (
              <p>
                <span>{t('reserve.tripToOrigin') + ': '}</span>
                <span>{'$' + price.tripToOrigin.toFixed(2)}</span>
              </p>
            )}
            {price.hourlyPrice > 0 && (
              <p>
                <span>{t('reserve.hourlyPrice') + ': '}</span>
                <span>{'$' + price.hourlyPrice.toFixed(2)}</span>
              </p>
            )}
            {price.gst > 0 && (
              <p>
                <span>{t('reserve.gst') + ': '}</span>
                <span>{'$' + price.gst.toFixed(2)}</span>
              </p>
            )}
            {price.qst > 0 && (
              <p>
                <span>{t('reserve.qst') + ': '}</span>
                <span>{'$' + price.qst.toFixed(2)}</span>
              </p>
            )}
            {price.overnight > 0 && (
              <p>
                <span>{t('reserve.overnightCharge') + ': '}</span>
                <span>{'$' + price.overnight.toFixed(2)}</span>
              </p>
            )}
            {price.airportFee > 0 && (
              <p>
                <span>{t('reserve.airportFee') + ': '}</span>
                <span>{'$' + price.airportFee.toFixed(2)}</span>
              </p>
            )}
            {price.returnAirportFee > 0 && (
              <p>
                <span>{t('reserve.returnAirportFee') + ': '}</span>
                <span>{'$' + price.returnAirportFee.toFixed(2)}</span>
              </p>
            )}
            {price.petFee > 0 && (
              <p>
                <span>{t('reserve.petFee') + ': '}</span>
                <span>{'$' + price.petFee.toFixed(2)}</span>
              </p>
            )}
            {price.meetGreetFee > 0 && (
              <p>
                <span>{t('reserve.meetGreetFee') + ': '}</span>
                <span>{'$' + price.meetGreetFee.toFixed(2)}</span>
              </p>
            )}
            {price.total > 0 && (
              <p className="Res-total-price">
                <span>{t('reserve.total') + ': '}</span>
                <span>{'$' + price.total.toFixed(2)}</span>
              </p>
            )}
          </div>
        )}

        {/* TERM AGREEMENT */}
        <div style={{ padding: '25px 0px 10px 0px' }}>
          <FormControlLabel
            required
            control={<Checkbox onClick={() => setAgreeTerm(!agreeTerm)} />}
            label={t('reserve.agreeTerm')}
          />
        </div>
        <div>
          <FormControlLabel
            required
            control={<Checkbox onClick={() => setAgreePrice(!agreePrice)} />}
            label={t('reserve.agreePrice')}
          />
        </div>
        {/* TERM AGREEMENT */}
      </CustomModal>
    </>
  );
};

export default Reservation;
